import React from 'react';
import {Link} from 'react-router-dom';
import { data, images } from '../../constants';
import { Notes, SubHeading } from '../../components';
import Carousel from '../../components/Carousel/Carousel';

import './Header.css';



const Header = () => {

  return (
    <div className="app__header  app__bg2">
      <div data-aos="zoom-out" className="app__header-img">
        <Carousel slides={data.imageData} />
      </div>
      <div className="app__wrapper-info section__padding">
        <Notes/>
        <div className='app__wrapper-info__title'>
          <SubHeading title="Начало" />
          <h1 data-aos="fade-right" className="headtext__cormorant">За Кантората</h1>
        </div>

        <div className='app__wrapper-info__sign'>
          <div className='app__wrapper-info__sign-img'>
            <a href="https://bcpea.org/" target="blank" rel='noreferrer'><img src={images.sign} alt="sign-bcpea" /></a>
          </div>
          <div data-aos="fade-right"><span className='headtext__cormorant'>ЧСИ ГЕОРГИ КАРИМОВ</span>
            <p className='p__cormorant'>  рег. № 792 в Камарата на Частните съдебни изпълнители</p>
          </div>
        </div>
        <div className='app__header-content'data-aos="fade-left">
          <p  className="p__opensans" >
          &emsp;&emsp;Кантората на ЧСИ Георги Каримов се стреми да предлага висококачествени услуги в областта на принудителното изпълнение, отговарящи на високи професионални етични стандарти.<Link to="/sphere"> <span>... Виж повече за предлаганите услуги</span></Link>
          </p>
          <br />
          <p  className="p__opensans" >
          &emsp;&emsp; Кантората е основана през 2006 г. Намира се в центъра на град Самоков, в непосредствена близост до Районен съд - Самоков и Община Самоков.
          </p>
          <br />
          <p  className="p__opensans" >  
          &emsp;&emsp; Районът на действие на ЧСИ Георги Каримов е в района на Софийски окръжен съд, съвпада с границите на Софийска област и обхваща територията на 22  общини - Ботевград, Правец, Елин Пелин, Горна Малина, Етрополе, Ихтиман, Костенец, Долна баня, Костинброд, Годеч, Пирдоп, Копривщица, Антон, Златица, Челопеч, Чавдар, Мирково, Самоков, Своге, Сливница, Драгоман, Божурище. <Link to="/range"> <span>... Виж повече за района на действие</span></Link>
          </p>
          <br />
          <p  className="p__opensans" >  
          Адрес на кантората: гр. Самоков, ул. Македония“ № 34А, ет. 3, офис 303   <a href='#address'> <span>... Виж на картата</span></a>
          </p>
          <p  className="p__opensans" >  
          Работно време: Понеделник-Петък от 08:00 до 17:00 часа.
          </p>
          <p  className="p__opensans" >  
         Телефон за връзка: 0722/6-01-83
          </p>
          <p  className="p__opensans" >  
         Email: kantora.karimov@abv.bg
          </p>
          <p  className="p__opensans" >  
         Банкови сметки на ЧСИ Георги Каримов <a href='#bankovismetki'> <span>... Виж тук</span></a>
          </p>
        </div >
      </div>
    </div>
  );
}
export default Header;
