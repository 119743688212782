import line from '../assets/line.png';
import linegold from '../assets/linegold.png';
import logo from '../assets/logo.png';
import logowhite from '../assets/logo-white.png';
import image01 from '../assets/image01.webp';
import image02 from '../assets/image02.webp';
import image03 from '../assets/image03.webp';
import image04 from '../assets/image04.webp';
import gradient from '../assets/gradient.webp';
import man from '../assets/man.webp';
import office from '../assets/office.webp';
import office2 from '../assets/office2.webp';
import services from '../assets/services.webp';
import properties from '../assets/properties.webp';
import documents from '../assets/documents.webp';
import car from '../assets/car.webp';
import house from '../assets/house.webp';
import map from '../assets/map.webp';
import powers from '../assets/powers.webp';
import loader from '../assets/loader.gif';
import sign from '../assets/sign.png';
import powers2 from '../assets/powers2.webp';



export default { 
  line,
  linegold,
  logo,
  logowhite,
  image01,
  image02,
  image03,
  image04,
  gradient,
  man,
  office,
  office2,
  services,
  properties,
  documents,
  car,
  house,
  map,
  powers,
  loader,
  sign,
  powers2
};
