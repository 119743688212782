import React from 'react'

const Fees03 = () => {
  return (
    <div className='fade-in'>
      <p className='p__custom'> 28. За извършване от частния съдебен изпълнител на действие по индивидуално принудително изпълнение в неработното му време, в почивни или празнични дни се събира допълнителна такса в размер 50 на сто от таксата за съответното действие.
Забележка. Допълнителна такса не се събира за извършване на действията по т. 20 – 24.</p>    
    </div>
  )
}

export default Fees03


