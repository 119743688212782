import React from 'react';

const Fees01 = () => {
  return (
    <div className='fade-in'>
      <p className='p__custom'>
      1. За образуване на изпълнително дело се събира такса 20 лв.
      </p>
      <p className='p__custom'>2. За цялостно проучване имущественото състояние на длъжника, набавяне на данни, документи, книжа и други, както и за определяне начина на изпълнение от частния съдебен изпълнител, се събира такса 50 лв.</p>
      <p className='p__custom'>3. За извършване на всяка справка за длъжника и неговото имущество се събира такса 5 лв.</p>
      <p className='p__custom'>4. За изпращане по пощата на призовка, препис от жалба, уведомление и книжа се събира такса 10 лв.</p>
      <p className='p__custom'>5. (изм. – ДВ, бр. 24 от 2013 г.) За изготвяне и връчване от съдебния изпълнител или от негов служител на покана, призовка, препис от жалба, уведомление или книжа, включително за връчване на изявление на взискателя по чл. 517, ал. 2 и 3 от Гражданския процесуален кодекс (ГПК), се събира такса 20 лв.</p>
      <p className='p__custom'>6. За издаване на удостоверение се събира такса 5 лв.</p>
      <p className='p__custom'>7. За издаване на препис от документ, съставен от частен съдебен изпълнител, се събира такса 2 лв., а ако преписът е повече от една страница – за всяка следваща по 1 лв.</p>
      <p className='p__custom'>8. За изготвяне на копие от изпълнително дело се събира такса 10 лв.
      </p>
      <p className='p__custom'><i>Забележка.</i> За изготвяне на препис на изпълнително дело и предоставянето му на компетентния съд се събира такса 20 лв.</p>
      <p className='p__custom'>9. (изм. – ДВ, бр. 24 от 2013 г.) За налагане на запор без извършване на опис, включително върху дял от търговско дружество по чл. 517, ал. 1 ГПК, се събира такса 15 лв.
      </p>
      <p className='p__custom'><i>Забележка.</i>(нова – ДВ, бр. 24 от 2013 г.) За налагане на електронен запор върху вземане по сметка/и в банка/и се събира такса 15 лв. независимо от броя на запорите.</p>
      <p className='p__custom'>10. За искане до съдията по вписванията за вписване или вдигане на възбрана се събира такса 15 лв.</p>
      <p className='p__custom'> 11. За присъединяване на взискател се събира такса 50 лв.</p>
      <p className='p__custom'>12. За изготвяне на сметка за размера на дълга от частния съдебен изпълнител се събира такса 30 лв.</p>
      <p className='p__custom'>13. За изготвяне и предявяване на разпределение се събира такса 30 лв.</p>
      <p className='p__custom'>14. (изм. – ДВ, бр. 24 от 2013 г.) За овластяване на взискателя по чл. 517, ал. 2 и 3 ГПК да предяви иск за прекратяване на дружеството се събира такса 20 лв.</p> 
      <p className='p__custom'>15. За извършване продажба на безналични ценни книжа се събира такса 100 лв.</p>
      <p className='p__custom'>16. (изм. – ДВ, бр. 24 от 2013 г.) За изпълнение на определено действие, предвидено в глава четиридесет и осма от ГПК се събира такса 50 лв.</p>
      <p className='p__custom'><i>Забележка.</i> Същата такса се събира и за осъществяване режим на лични отношения и предаване на дете.</p>
      <p className='p__custom'> 17. За предоставяне и възлагане на вземане за събиране или вместо плащане се събира такса 20 лв.</p>
      <p className='p__custom'>18. За изпълнение на обезпечителни мерки се събират таксите, предвидени за съответните действия в този раздел.</p>
      <p className='p__custom'>19. За предоставяне на документи и информация относно публичната продан или продажбата по Закона за особените залози (ЗОЗ) се събира такса 20 лв.</p>
   </div>
  )
}

export default Fees01;
