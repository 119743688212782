import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdArrowDropDown } from 'react-icons/md';
import { MdClose } from "react-icons/md";
import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
    const [click, setClick] = useState(false);
    const [toggleMenu, setToggleMenu] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [subMenuServices, setSubMenuServices] = useState(false);
    const [subMenuSales, setSubMenuSales] = useState(false);

    const handleClick = () => {
        setClick(!click);
        setToggleMenu(!toggleMenu);
        if (toggleMenu === true) {
            setIsOpen(true);
        }
    };

    if (toggleMenu) {
        document.body.style.overflowY = "hidden";
        // document.body.style.position = "fixed";

    } else {
        document.body.style.overflowY = "visible";
        // document.body.style.removeProperty("position");
    }

    return (
        <nav className='app__navbar'>
            <div className='app__navbar-logo'>
                <Link to="/"><img src={images.logowhite} alt="logo" /></Link>
                <div className="app__navbar-logo_title">
                    <h2 className="p__opensans">Георги Каримов</h2>
                    <p className="p__opensans">Частен съдебен изпълнител</p>
                </div>
            </div>     
            <ul className='app__navbar-links'>
                <li className='p__opensans'>
                    <Link to='/'>Начало</Link>
                </li>
                <li className='p__opensans'>
                    <Link to="/about">За нас</Link>                   
                </li>
                <li style={{ position: "relative" }} className='p__opensans' onMouseLeave={() => setSubMenuServices(false)}>
                    <Link to="/services"onMouseEnter={() => setSubMenuServices(true)}>Услуги  <MdArrowDropDown size={'1.5em'}/></Link>             
                    {subMenuServices && <ul className='app__navbar-links__subMenu slide-bottom'>
                        <li className='p__opensans'><Link to='/sphere'>Сфери на дейност</Link></li>
                        <li className='p__opensans'><Link to='/powers'>Правомощия</Link></li>
                        <li className='p__opensans'><Link to='/range'>Район на действие</Link></li>
                    </ul>
                    }
                </li>
                <li className='p__opensans'>
                    <Link to="/tariff/fees01">Тарифа</Link>                    
                </li>
                <li style={{ position: "relative" }} className='p__opensans' onMouseLeave={() => setSubMenuSales(false)}>
                    <Link to="/sales" onMouseEnter={() => setSubMenuSales(true)}>Продажби <MdArrowDropDown size={'1.5em'}/></Link>                   
                    {subMenuSales && <ul className='app__navbar-links__subMenu slide-bottom' >
                        <li className='p__opensans'><a href="https://sales.bcpea.org/properties?judge=792" target="_blank" rel='noreferrer'>Недвижими имоти</a></li>
                        <li className='p__opensans'><a href="https://sales.bcpea.org/vehicles?judge=792" target="_blank" rel='noreferrer'>МПС и друи движими вещи</a></li>
                        <li className='p__opensans'><Link to='/blanks'>Бланки и условия</Link></li>
                    </ul>
                    }
                </li>
                <li className='p__opensans'>
                    <Link to="/contacts">Контакти</Link>                   
                </li>
            </ul>
           
            <div className="app__navbar__menu-icon">
                {toggleMenu ? (
                    <MdClose fontSize={27} color='#DCCA87' onClick={handleClick} />
                ) : (
                    <GiHamburgerMenu color='#fff' fontSize={27} onClick={handleClick} />
                )}
            </div>
            <div>
                {toggleMenu ? (
                    <div className='app__navbar-smallscreen_overlay flex__center slide-right'>
                        <ul className='app__navbar-smallscreen_links'>
                            <li className='p__opensans' onClick={() => setToggleMenu(false)}>
                                <Link to='/'>Начало</Link>
                            </li>
                            <li className='p__opensans' onClick={() => setToggleMenu(false)}>
                            <Link to="/about">За нас</Link>
                            </li>

                            <li style={{ position: "relative" }}
                             className='p__opensans'
                             onMouseLeave={() => setSubMenuServices(false)}
                             onClick={() => setToggleMenu(false)}>
                               <Link to="/services" onMouseEnter={() => setSubMenuServices(true)}>Услуги <MdArrowDropDown size={'1.5em'} /></Link>                             
                                {subMenuServices && <ul className='app__navbar-links__subMenu-smallscreen slide-bottom' >
                                    <li className='p__opensans'><Link to='/sphere'>Сфери на дейност</Link></li>
                                    <li className='p__opensans'><Link to='/powers'>Правомощия</Link></li>
                                    <li className='p__opensans'><Link to='/range'>Район на действие</Link></li>
                                </ul>
                                }
                            </li>
                            <li className='p__opensans' onClick={() => setToggleMenu(false)}>
                                <Link to="/tariff/fees01">Тарифа</Link>                               
                            </li>

                            <li style={{ position: "relative" }}
                             className='p__opensans'
                             onMouseLeave={() => setSubMenuSales(false)}
                             onClick={() => setToggleMenu(false)}>
                                <Link to="/sales" onMouseEnter={() => setSubMenuSales(true)}>Продажби <MdArrowDropDown size={'1.5em'} /></Link>                              
                                {subMenuSales && <ul className='app__navbar-links__subMenu-smallscreen slide-bottom'>
                                    <li className='p__opensans'><a href="https://sales.bcpea.org/properties?judge=792" target="_blank" rel='noreferrer'>Недвижими имоти</a></li>
                                    <li className='p__opensans'><a href="https://sales.bcpea.org/vehicles?judge=792" target="_blank" rel='noreferrer'>МПС и друи движими вещи</a></li>
                                    <li className='p__opensans' onClick={() => setToggleMenu(false)}><Link to='/blanks'>Бланки и условия</Link></li>
                                </ul>
                                }
                            </li>
                            <li className='p__opensans' onClick={() => setToggleMenu(false)}>
                            <Link to="/contacts">Контакти</Link>
                            </li>
                        </ul>
                    </div>
                ) : (
                    <>
                        {isOpen && (
                            <div className='app__navbar-smallscreen_overlay flex__center slide-left' onClick={() => setToggleMenu(false)}>
                                <ul className='app__navbar-smallscreen_links fade'>
                                    {/* <li className='p__opensans'>
                                        <Link to='/'>Начало</Link>
                                    </li>
                                    <li className='p__opensans'>
                                        <a href='#about'>За нас</a>
                                    </li>
                                    <li className='p__opensans'>
                                        <a href='#services'>Услуги</a>
                                    </li>
                                    <li className='p__opensans'>
                                        <a href='#tariff'>Тарифа</a>
                                    </li>
                                    <li className='p__opensans'>
                                        <a href='#sales'>Продажби</a>
                                    </li>
                                    <li className='p__opensans'>
                                        <a href='#contacts'>Контакти</a>
                                    </li> */}
                                </ul>
                            </div>
                        )}
                    </>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
