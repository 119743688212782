import React from 'react'
import { Sphere, Footer } from '../../container';
import {Navbar } from '../../components';

const SpherePage = () => {

  return (
    
    <div>
       <Navbar/>
        <Sphere/>
        <Footer/>
    </div>
  )
}

export default SpherePage;
