import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from '../../components';
import { Footer } from '../../container';
import './NotFound.css';

const NotFound = () => {
  return (
  <>
  <Navbar/>
   <div className='app__notfound app__bg3'>   
        <h1 className='headtext__cormorant'>404</h1>
      <p className='p__opensans'>Страницата не е намерена или не съществува</p> 
      <Link to="/"><button type="button" className="custom__button">Обратно към начало</button></Link>   
    </div>   
    <Footer/>
  </>  
  )
}

export default NotFound;
