import application1 from '../assets/data/application-1.docx';
import application2 from '../assets/data/application-2.docx';
import offer from '../assets/data/offer.docx';
import applicationReturn from '../assets/data/application-return-deposit.docx';
import declarationNP from '../assets/data/declaration-NP.pdf';
import declarationLP from '../assets/data/declaration-LP.pdf';
import appendix7NP from '../assets/data/Appendix7-NP.pdf';
import appendix7LP from '../assets/data/Appendix7-LP.pdf';

import{images} from '../constants';


const imageData = [
  {
    image: images.image01,
    title: "17 години професионална дейност",
    path: "/about", 
    text:"За нас",
  },
  {
    image: images.image02,
    title: "Професионални услуги и район на действие",
    path: "/services", 
    text:"Вижте повече", 
  },
  {
    image: images.image03,
    title: "Продажби и документи за публична продан",
    path: "/sales", 
    text:"Вижте повече", 
  },
  {
    image: images.image04,
    title: "Свържете се с нас",
    tel:"0722/60183",
    mobile:"0888/437307",
    path: "/contacts", 
    text:"Контакти",
  },
];
const fees = ["Обикновени такси","Пропорционални такси","Допълнителна такса","Авансови такси","Субсидирани такси","Допълнителни разноски","Плащане на таксите" ]

export default {
    application1,
    application2,
    offer,
    applicationReturn,
    declarationNP,
    declarationLP,
    appendix7NP,
    appendix7LP,   
    imageData,
    fees
}

