import React, { useEffect, useState } from "react";
import { Routes, Route, NavLink } from "react-router-dom";
import { Footer } from "../../container";
import { Navbar, Fees01, Fees02, Fees03, Fees04, Fees05, Fees06, Fees07, BankAccounts, SubHeading } from "../../components";
import { data } from '../../constants';
import './TariffPage.css';


const TariffPage = () => {
  const [click, setClick] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [feeTitle, setFeeTitle] = useState('');

  const path = window.location.pathname;

  useEffect(() => {
    setNameByUrl(path);
  }, [path]);

  const setNameByUrl = (path) => {
    switch (path) {
      case "/tariff/fees01":
        setFeeTitle(data.fees[0]);     
        break;
      case "/tariff/fees02":
        setFeeTitle(data.fees[1]);
        break;
      case "/tariff/fees03":
        setFeeTitle(data.fees[2]);
        break;
      case "/tariff/fees04":
        setFeeTitle(data.fees[3]);
        break;
      case "/tariff/fees05":
        setFeeTitle(data.fees[4]);
        break;
      case "/tariff/fees06":
        setFeeTitle(data.fees[5]);
        break;
      case "/tariff/fees07":
        setFeeTitle(data.fees[6]);
        break;
      default:
        break;
    }
  }

  const handleClick = () => {
    setClick(!click);
    setToggleMenu(!toggleMenu);
  };
  const handleTitle = (e) => {
    setToggleMenu(false);
    setFeeTitle(e.target.innerHTML);
  }

  return (
    <>
      <Navbar />
      <div className='app__tariffPage flex__center section__padding app__bg'>

        <div data-aos="fade-down"className='app__tariff-title'>
          <SubHeading title='Тарифа' />
          <h1 className='headtext__cormorant'> Тарифа</h1>
          <p className='p__opensans'>За таксите и разноските към Закона за частните съдебни изпълнители</p>
        </div>
        <div data-aos="fade-left" className="app__tariffPage-button" onClick={handleClick}> <span>Виж всички такси</span></div>

        <div className="app__tariffPage-wrapper">
          <nav className="app__tariffPage-nav">
            <ul className="app__tariffPage-nav__links">

              <li className='p__opensans'><NavLink to="/tariff/fees01">Обикновени такси</NavLink></li>
              <li className='p__opensans'><NavLink to="/tariff/fees02" >Пропорционални такси</NavLink></li>
              <li className='p__opensans'><NavLink to="/tariff/fees03">Допълнителна такса</NavLink> </li>
              <li className='p__opensans'><NavLink to="/tariff/fees04">Авансови такси</NavLink></li>
            </ul>
            <ul className="app__tariffPage-nav__links">
              <li className='p__opensans'><NavLink to="/tariff/fees05">Субсидирани такси</NavLink></li>
              <li className='p__opensans'><NavLink to="/tariff/fees06">Допълнителни разноски</NavLink></li>
              <li className='p__opensans'><NavLink to="/tariff/fees07">Плащане на таксите</NavLink></li>
            </ul>
          </nav>


          {
            toggleMenu &&
            <nav className="app__tariffPage-nav__smallscreen section__padding slide-bottom ">
              <ul className="app__tariffPage-nav__links-smallscreen">
                <li className='p__opensans'><NavLink to="/tariff/fees01" end onClick={handleTitle} >Обикновени такси</NavLink></li>
                <li className='p__opensans'><NavLink to="/tariff/fees02" onClick={handleTitle}>Пропорционални такси</NavLink></li>
                <li className='p__opensans'><NavLink to="/tariff/fees03" onClick={handleTitle}>Допълнителна такса</NavLink> </li>
                <li className='p__opensans'><NavLink to="/tariff/fees04" onClick={handleTitle}>Авансови такси</NavLink></li>
                <li className='p__opensans'><NavLink to="/tariff/fees05" onClick={handleTitle}>Субсидирани такси</NavLink></li>
                <li className='p__opensans'><NavLink to="/tariff/fees06" onClick={handleTitle}>Допълнителни разноски</NavLink></li>
                <li className='p__opensans'><NavLink to="/tariff/fees07" onClick={handleTitle}>Плащане на таксите</NavLink></li>
              </ul>
            </nav>
          }
          <div data-aos="fade-right" className="app__tariffPage-feeTitle"><span>{feeTitle}</span></div>
          <div data-aos="fade-up"className="app__tariffPage-content">
         
            <div  className="app__tariffPage-content__paragraph">
            
              <Routes>
                <Route path='fees01' element={<Fees01 />} />
                <Route path='fees02' element={<Fees02 />} />
                <Route path='fees03' element={<Fees03 />} />
                <Route path='fees04' element={<Fees04 />} />
                <Route path='fees05' element={<Fees05 />} />
                <Route path='fees06' element={<Fees06 />} />
                <Route path='fees07' element={<Fees07 />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
      <BankAccounts />
      <Footer />
    </>
  );
};

export default TariffPage;
