import React from "react";
import {SlLocationPin} from 'react-icons/sl';
import {HiOutlinePhone, HiOutlineMail} from 'react-icons/hi';
import {BsPhone} from 'react-icons/bs';
import {LuClock7} from 'react-icons/lu';

import { images } from "../../constants";

import './Footer.css';

const Footer = () => {
  return (
    <div className='app__footer app__bg3 section__padding'>
      
      <div className='app__footer-content'>

        <div className='app__footer-contact'>
          <h1 className='app__footer-headtext'>Свържете се с нас</h1>
          <p className='p__opensans'> <SlLocationPin className="icon"/> гр. Самоков, ул. "Македония" № 34А, ет.3, офис 303</p>
          <p className='p__opensans'><HiOutlinePhone className="icon"/> 0722/6-01-83</p>
          <p className='p__opensans'><BsPhone className="icon"/> 0888/43-73-07</p>
          <p className='p__opensans'><HiOutlineMail className="icon"/>gkarimov@gmail.com</p>
        </div>

        <div className='app__footer-logo'>
          <img src={images.logowhite} alt='footer_logo' />
          <p className='p__opensans'>
            &quot;Повече от цивилизацията, справедливостта е нуждата на хората.&quot;
          </p>
          <span className="p__opensans">(Пиетро Колета)</span>
          <img
            src={images.linegold}
            className='line__img' alt="line__image"
            style={{ marginTop: 15 }}
          />
        </div>

        <div className='app__footer-work'>
          <h1 className='app__footer-headtext'>Работно време</h1>
          <p className='p__opensans'>Понеделник-Петък:</p>
          <p className='p__opensans'><LuClock7 className="icon"/>08:00 - 17:00 </p>
        </div>

      </div>   
      <div className='footer__copyright'>
        <p className='p__opensans'>2023 Karimov's office.</p>       
      </div>
    </div>
  );
};

export default Footer;
