import React, { useState } from "react";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { HiPlus, HiMinus } from "react-icons/hi";
import "./DistrictCourts.css";

const DistrictCourts = () => {
  const [showDiv, setShowDiv] = useState("");

  return (
    <div data-aos="zoom-in" className='app__districtcourts'>
      <ul className='app__districtcourts-list'>
        <li
          className='app__districtcourts-item'
          onClick={() => setShowDiv("botevgrad")}
        >
          {showDiv === "botevgrad" ? <HiMinus /> : <HiPlus />}{" "}
          <span>
            РАЙОНЕН СЪД БОТЕВГРАД{" "}
            <a
              href='https://botevgrad-rs.justice.bg'
              target='_blank'
              rel='noreferrer'
            >
              <MdKeyboardDoubleArrowRight />
            </a>
          </span>
        </li>
        {showDiv === "botevgrad" && (
          <div className='app__districtcourts-town slide-bottom'>
            <a href='https://botevgrad.bg/' target='blank' rel='noreferrer'>
              <h2>Община Ботевград</h2>{" "}
            </a>
            <p>
              гр. Ботевград, с. Боженица, с. Врачеш, с. Гурково, с. Елов дол, с.
              Липница, с. Литаково, с. Новачене, с. Радотина, с. Разлив, с.
              Рашково, с. Скравена, с. Трудовец
            </p>
            <a href='https://pravets.bg/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Правец</h2>
            </a>

            <p>
              гр. Правец, с. Видраре, с. Джурово, с. Калугерово, с.
              Манасел.река, с. Осиковица, с. Осиковска лъкавица, с. Пр.
              лъкавица, с. Равнище, с. Своде
            </p>
          </div>
        )}
        <li
          className='app__districtcourts-item'
          onClick={() => setShowDiv("elinpelin")}
        >
          {showDiv === "elinpelin" ? <HiMinus /> : <HiPlus />}
          <span>
            РАЙОНЕН СЪД ЕЛИН ПЕЛИН{" "}
            <a
              href='https://elpelin-rs.justice.bg'
              target='_blank'
              rel='noreferrer'
            >
              <MdKeyboardDoubleArrowRight />
            </a>
          </span>
        </li>
        {showDiv === "elinpelin" && (
          <div className='app__districtcourts-town slide-bottom'>
            <a
              href='https://www.elinpelin.org/'
              target='blank'
              rel='noreferrer'
            >
              <h2>Община Елин Пелин</h2>{" "}
            </a>
            <p>
              гр.Елин Пелин, с. гара Елин Пелин, с. Богданлия, с. Г.Раковица, с.
              Габра, с. Григорево, с. Доганово, с. Елешница, с. Караполци, с.
              Крушовица, с. Лесново, с. Мусачево, с. Нови хан, с. Огняново, с.
              Петково, с. Потоп, с. Равно поле, с. Столник, с. Чурек
            </p>
            <a
              href='https://www.gornamalina.bg/'
              target='blank'
              rel='noreferrer'
            >
              {" "}
              <h2>Община Горна Малина</h2>{" "}
            </a>
            <p>
              с. Горна Малина, с. Априлово, с. Байлово, с. Белопопци, с.
              Гайтанево, с. Горно Камарци, с. Долна Малина, с. Долно Камарци, с.
              Макоцево, с. Негушево, с. Осойца, с. Саранци, с. Стъргел, с.
              Чеканчево
            </p>
          </div>
        )}
        <li
          className='app__districtcourts-item'
          onClick={() => {
            setShowDiv("etropole");
          }}
        >
          {showDiv === "etropole" ? <HiMinus /> : <HiPlus />}
          <span>
            РАЙОНЕН СЪД ЕТРОПОЛЕ{" "}
            <a
              href='https://etropole-rs.justice.bg'
              target='_blank'
              rel='noreferrer'
            >
              <MdKeyboardDoubleArrowRight />
            </a>
          </span>
        </li>
        {showDiv === "etropole" && (
          <div className='app__districtcourts-town slide-bottom'>
            <a href='http://etropole.bg/' target='blank' rel='noreferrer'>
              <h2>Община Етрополе</h2>
            </a>
            <p>
              гр. Етрополе, с. Бойковец, с. Брусен, с. Горунака, с. Лопян, с.
              Лъга, с. Малък Искър, с. Оселна, с. Рибарица, с. Ямна
            </p>
          </div>
        )}
        <li
          className='app__districtcourts-item'
          onClick={() => {
            setShowDiv("ihtiman");
          }}
        >
          {showDiv === "ihtiman" ? <HiMinus /> : <HiPlus />}
          <span>
            РАЙОНЕН СЪД ИХТИМАН{" "}
            <a
              href='https://ihtiman-rs.justice.bg'
              target='_blank'
              rel='noreferrer'
            >
              <MdKeyboardDoubleArrowRight />
            </a>
          </span>
        </li>
        {showDiv === "ihtiman" && (
          <div className='app__districtcourts-town slide-bottom'>
            <a href='https://ihtiman.bg/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Ихтиман</h2>{" "}
            </a>
            <p>
              гр. Ихтиман, с. Белица, с. Боерица, с. Борика, с. Бузяковци, с.
              Вакарел, с. Венковец, с. Веринско, с. Живково, с. Костадинкино, с.
              Мирово, с. Мухово, с. Пауново, с. Полянци, с. Стамболово, с.
              Черньово, ЗАБЕЛЕЖКА: с.Марица и с.Гуцал са в община Самоков
            </p>
            <a href='https://kostenetz.com/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Костенец</h2>{" "}
            </a>
            <p>
              гр. Костенец, с. Горна Василица, с. Долна Василица, с. Очуша, с.
              Подгорие, с. Пчелин
            </p>
            <a href='http://dolna-banya.net/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Долна баня</h2>{" "}
            </a>
            <p>гр. Долна Баня</p>
          </div>
        )}

        <li
          className='app__districtcourts-item'
          onClick={() => {
            setShowDiv("kostinbrod");
          }}
        >
          {showDiv === "kostinbrod" ? <HiMinus /> : <HiPlus />}
          <span>
            РАЙОНЕН СЪД КОСТИНБРОД{" "}
            <a
              href='https://kostinbrod-rs.justice.bg'
              target='_blank'
              rel='noreferrer'
            >
              <MdKeyboardDoubleArrowRight />
            </a>
          </span>
        </li>
        {showDiv === "kostinbrod" && (
          <div className='app__districtcourts-town slide-bottom'>
            <a href='https://kostinbrod.bg/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Костинброд</h2>{" "}
            </a>
            <p>
              гр. Костинброд, с. Безден, с. Бодьовци, с. Бучин проход, с.
              Голяновци, с. Градец, с. Драговищица, с. Дръмша, с. Опицвет, с.
              Петърч, с. Понор, с. Царичина, с. Чибаовци
            </p>
            <a href='https://www.godech.bg/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Годеч</h2>{" "}
            </a>
            <p>
              гр. Годеч, с. Бракьовци, с. Букоровци, с. Бърля, с. Връбница, с.
              Връдловци, с. Гинци, с. Голеш, с. Губеш, с. Каленовци, с. Комщина,
              с. Лопушна, с. Мургаш, с. Равна, с. Разбойще, с. Ропот, с. Смолча,
              с. Станянци, с. Туден, с. Шума
            </p>
          </div>
        )}

        <li
          className='app__districtcourts-item'
          onClick={() => {
            setShowDiv("pirdop");
          }}
        >
          {showDiv === "pirdop" ? <HiMinus /> : <HiPlus />}
          <span>
            РАЙОНЕН СЪД ПИРДОП{" "}
            <a
              href='https://pirdop-rs.justice.bg'
              target='_blank'
              rel='noreferrer'
            >
              <MdKeyboardDoubleArrowRight />
            </a>
          </span>
        </li>
        {showDiv === "pirdop" && (
          <div className='app__districtcourts-town slide-bottom'>
            <a href='https://pirdop.bg/' target='blank' rel='noreferrer'>
              <h2>Община Пирдоп</h2>{" "}
            </a>
            <p>гр. Пирдоп, с. Душанци</p>
            <a
              href='https://koprivshtitsa-bg.com/'
              target='blank'
              rel='noreferrer'
            >
              {" "}
              <h2>Община Копривщица</h2>{" "}
            </a>
            <p>гр.Копривщица</p>
            <a href='https://antonbg.eu/' target='blank' rel='noreferrer'>
              <h2>Община Антон</h2>{" "}
            </a>
            <p className='p__opensans'>с.Антон</p>
            <a href='https://www.zlatitsa.bg/' target='blank' rel='noreferrer'>
              <h2>Община Златица</h2>{" "}
            </a>

            <p>гр. Златица, с. Карлиево, с. Петрич и с. Църквище</p>
            <a
              href='https://www.chelopech.org/'
              target='blank'
              rel='noreferrer'
            >
              <h2>Община Челопеч</h2>{" "}
            </a>

            <p>с.Челопеч</p>
            <a href='https://www.chavdar.eu/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Чавдар</h2>{" "}
            </a>

            <p>с. Чавдар</p>
            <a href='https://www.mirkovo.bg/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Мирково</h2>{" "}
            </a>

            <p>с. Мирково, с. Бенковски, с. Буново, с. Каменица и с. Смолско</p>
          </div>
        )}

        <li
          className='app__districtcourts-item'
          onClick={() => {
            setShowDiv("samokov");
          }}
        >
          {showDiv === "samokov" ? <HiMinus /> : <HiPlus />}
          <span>
            РАЙОНЕН СЪД САМОКОВ{" "}
            <a
              href='https://samokov-rs.justice.bg'
              target='_blank'
              rel='noreferrer'
            >
              <MdKeyboardDoubleArrowRight />
            </a>
          </span>
        </li>
        {showDiv === "samokov" && (
          <div className='app__districtcourts-town slide-bottom'>
            <a href='https://samokov.bg/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Самоков</h2>
            </a>

            <p>
              гр. Самоков, с. Алино, с. Бели Искър, с. Белчин, курорт Боровец,
              с. Говедарци, с. Горни Окол, с. Гуцал, с. Долни Окол, с. Доспей,
              с. Драгошиново, с. Злокучане, с. Клисура, с. Ковачевци, с. Лисец,
              с. Маджаре, с. Мала Църква, с. Марица, с. Ново село, с. Поповяне,
              с. Продановци, с. Радуил, с. Райово, с. Рельово, с. Широки дол, с.
              Шопочан, с. Яребковица, с. Ярлово, ЗАБЕЛЕЖКА: с. Гуцал и с.Марица
              са в съдебния район на РС-Ихтиман
            </p>
          </div>
        )}
        <li
          className='app__districtcourts-item'
          onClick={() => {
            setShowDiv("svoge");
          }}
        >
          {showDiv === "svoge" ? <HiMinus /> : <HiPlus />}
          <span>
            РАЙОНЕН СЪД СВОГЕ{" "}
            <a
              href='https://svoge-rs.justice.bg'
              target='_blank'
              rel='noreferrer'
            >
              <MdKeyboardDoubleArrowRight />
            </a>
          </span>
        </li>
        {showDiv === "svoge" && (
          <div className='app__districtcourts-town slide-bottom'>
            <a href='https://www.svoge.bg/' target='blank' rel='noreferrer'>
              {" "}
              <h2>Община Своге</h2>{" "}
            </a>

            <p>
              гр. Своге, с. Батулия, с. Бов, с. Брезе, с. Брезовдол, с. Буковец,
              с. Вл.Тричков, с. Габровница, с. гара Бов, с. Губислав, с.
              Добърчин, с. Дружево, с. Еленовдол, с. Желен, с. Завидовци, с.
              Заноге, с. Заселе, с. Зимевица, с. Искрец, с. Лакатник, с. гара
              Лакатник, с. Лесковдол, с. Луково, с. Миланово, с. Огоя, с.
              Оплетня, с. Осеновлаг, с. Реброво, с. Редина, с. Свидня, с.
              Томпсън, с. Церецел, с. Церово, с. Ябланица
            </p>
          </div>
        )}
        <li
          className='app__districtcourts-item'
          onClick={() => {
            setShowDiv("slivnitsa");
          }}
        >
          {showDiv === "slivnitsa" ? <HiMinus /> : <HiPlus />}
          <span>
            РАЙОНЕН СЪД СЛИВНИЦА{" "}
            <a
              href='https://slivnitsa-rs.justice.bg'
              target='_blank'
              rel='noreferrer'
            >
              <MdKeyboardDoubleArrowRight />
            </a>
          </span>
        </li>
        {showDiv === "slivnitsa" && (
          <div className='app__districtcourts-town slide-bottom'>
            <a href='https://www.slivnitsa.bg/' target='blank' rel='noreferrer'>
              <h2>Община Сливница</h2>{" "}
            </a>

            <p>
              гр. Сливница, с. Алдомировци, с. Бехалии, с. Братушково, с.
              Бърложница, с. Гургулят, с. Гълъбовци, с. Драготинци, с. Извор
              махала, с. Пищане махала, с. Повалиръж, с. Пометковци, с.
              Радуловци, с. Ракита
            </p>
            <a
              href='https://www.obshtinadragoman.com/'
              target='blank'
              rel='noreferrer'
            >
              {" "}
              <h2>Община Драгоман</h2>{" "}
            </a>

            <p>
              гр. Драгоман, с. Беренде Извор, с. Беренде, с. Василовци, с.
              Вишан, с. Владиславци, с. Габер, с. Големо Малово, с. Горно Ново
              Село, с. Грълска падина, с. Долна Невля, с. Долно Ново Село, с.
              Драгоил, с. Дреатин, с. Калотина, с. Камбелевци, с. Круша, с.
              Летница, с. Липинци, с. Мало Малово, с. Махала Мало Белево, с.
              Начево, с. Неделище, с. Несла, с. Ново бърдо, с. Прекръсте, с.
              Раяновци, с. Табан, с. Цацаровци, с. Цръклевци, с. Чеканец, с.
              Чепърлянци, с. Чорул, с. Чукувезер, с. Ялботина
            </p>
            <a
              href='https://www.bozhurishte.bg/'
              target='blank'
              rel='noreferrer'
            >
              {" "}
              <h2>Община Божурище</h2>{" "}
            </a>

            <p>
              гр. Божурище, с. Гурмазово, с. Делян, с. Златуша, с. Мала
              Раковица, с. Пожарево, с. Пролеша, с. Росоман, с. Хераково,с.
              Белица, с. Храбърско
            </p>
          </div>
        )}
      </ul>
    </div>
  );
};

export default DistrictCourts;
