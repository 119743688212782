import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import { images } from '../../constants';
import { MdArrowForwardIos } from 'react-icons/md';
// import { FcDocument } from 'react-icons/fc';
// import { BsCarFrontFill, BsHouse } from 'react-icons/bs';
import { SubHeading, Banner } from '../../components';
import './Sales.css';
import AOS from "aos";
import "aos/dist/aos.css";



const Sales = () => {
    // useEffect(() => {
    //     AOS.init({ duration: 2000 });
    //     AOS.refresh();
    //   }, []);
    return (
        <div className='app__sales flex__center' id='sales'>      
             <Banner image={images.properties} bg={'app__bgblue'}/>
            <div className='app__sales-title'>
                <SubHeading title='Продажби' />
                <h1 data-aos="zoom-in" className='headtext__cormorant'> Публични продажби</h1>
            </div>
            
            <div className='app__sales-content section__padding'>
              
                <a href="https://sales.bcpea.org/properties?judge=792" target="_blank"rel='noreferrer'>
                <section data-aos="flip-right" className='app__sales-section'>
                        <div className='app__sales-section_item'>
                            <div className='app__sales-section_item-img'>
                                <img src={images.house} alt="documents" />
                            </div>
                            <div className='app__sales-section_item-content'>
                                <h3>Недвижими имоти</h3>
                                <MdArrowForwardIos className="app__sales-icon" />
                            </div>
                        </div>
                    </section>
                </a>
                <a href="https://sales.bcpea.org/vehicles?judge=792"target="_blank" rel='noreferrer'>
                <section data-aos="flip-right" className='app__sales-section'>
                        <div className='app__sales-section_item'>
                            <div className='app__sales-section_item-img'>
                                <img src={images.car} alt="documents" />
                            </div>
                            <div className='app__sales-section_item-content'>
                                <h3>МПС и други движими вещи</h3>
                                <MdArrowForwardIos className="app__sales-icon" />
                            </div>
                        </div>
                    </section>
                </a>                 
                <Link to="/blanks">
                <section data-aos="flip-right" className='app__sales-section'>
                        <div className='app__sales-section_item'>
                            <div className='app__sales-section_item-img'>
                                <img src={images.documents} alt="documents" />
                            </div>
                            <div className='app__sales-section_item-content'>
                                <h3>Бланки и условия за участие</h3>
                                <MdArrowForwardIos className="app__sales-icon" />
                            </div>
                        </div>
                    </section>
                </Link>
            </div>
        </div>
    )
}

export default Sales;
