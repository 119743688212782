import React from "react";
import { Navbar, Banner } from "../../components";
import { Footer, Contacts } from "../../container";
import {images} from '../../constants';

const ContactsPage = () => {
  return (
    <>
      <Navbar />     
      <div style={{overflow:'hidden'}}>
      <Banner image={images.image04} bg="app__bgblue" />
        <Contacts />
      </div>
      <Footer />
    </>
  );
};

export default ContactsPage;
