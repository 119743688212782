import React from "react";

import { Banner, Blanks, Navbar, SubHeading } from "../../components";
import { Footer } from "../../container";
import { images } from "../../constants";
import "./BlanksPage.css";

const BlanksPage = () => {
  const handleClick = (e) => {
    const elementHeader = e.target;
    const elementBody = elementHeader.nextElementSibling;
    console.log(elementHeader);
    if (!elementHeader.classList.contains("active")) {
      elementHeader.classList.add("active");
      elementBody.style.maxHeight = elementBody.scrollHeight + "px";
    } else {
      elementHeader.classList.remove("active");
      elementBody.style.maxHeight = 0;
    }
  };

  return (
    <>
      <Navbar />
      <div className='app__blanks app__bg'>
        <Banner image={images.properties} bg={"app__bgblue"} />
        <div className='app__blanks-title section__padding'>
          <SubHeading title='Бланки и условия' />
          <h3 data-aos='zoom-in'>Документи за публична продан</h3>
        </div>

        <Blanks />
      
        <div data-aos='fade-up' className='app__blanks-section flex__center'>     
          <div className='app__blanks-section__content'> 
            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3>Обявяване на проданта</h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Съдебният изпълнител е длъжен след изтичането на една
                    седмица от описа да изготви обявление за проданта, в което
                    посочва собственика на имота, описание на имота, ипотекиран
                    ли е той, има ли установени към момента на възбраната
                    ограничени вещни права върху имота, вписани искови молби,
                    възбрани и договори за наем, цената, от която ще започне
                    проданта, и мястото и деня, в който ще започне и ще завърши
                    проданта.
                  </p>
                  <p>
                    Обявлението по ал. 1 се поставя на съответните места в
                    канцеларията на частния съдебен изпълнител, в сградата на
                    районния съд, в общината или кметството по местонахождението
                    на имота, на самия имот и се публикува на интернет
                    страницата на окръжния съд по местоизпълнението, и то
                    най-малко един ден преди посочения в обявлението ден за
                    започване на проданта.
                  </p>
                  <p>
                    В деня по ал. 2 съдебният изпълнител съставя протокол, в
                    който посочва деня на разгласяване на обявлението.
                    Протоколът се регистрира в районния съд.
                  </p>
                  <p>
                    Съдебният изпълнител определя времето, през което
                    недвижимият имот може да бъде преглеждан от лицата, които
                    желаят да го купят.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3>Място на проданта </h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Проданта се извършва в сградата на районния съд. Тя
                    продължава един месец и завършва в посочения в обявлението
                    ден.
                  </p>
                  <p>
                    Книжата за проданта се държат в канцеларията на районния съд
                    на разположение на всеки, който се интересува от имота.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3> Наддавателни предложения </h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    За участие в наддаването по сметка на съдебния изпълнител се
                    внася задатък 10 на сто върху началната цена. Взискателят не
                    внася задатък, ако вземането му надвишава неговия размер.
                  </p>
                  <p>
                    Всеки наддавач посочва предложената от него цена с цифри и с
                    думи и подава предложението си заедно с квитанцията за
                    внесения задатък в запечатан плик. Наддавачът може да
                    направи само едно наддавателно предложение.
                  </p>
                  <p>
                    Предложенията се подават в канцеларията на районния съд,
                    което се отразява във входящия регистър.
                  </p>
                  <p>
                    Проданта приключва в края на работното време в последния
                    ден. Наддавателните предложения могат да се оттеглят
                    най-късно в този срок.
                  </p>
                  <p>
                    Наддавателни предложения от лица, които нямат право да
                    вземат участие в публичната продан, предложения за цена под
                    началната, както и предложения, надхвърлящи началната с
                    повече от 30 на сто, са недействителни.
                  </p>
                </div>
              </div>
            </div>
            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3> Лица без право да наддават </h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Длъжникът, неговият законен представител, длъжностните лица
                    от канцеларията на районния съд, служителите на съдебния
                    изпълнител, както и лицата, посочени в чл. 185 от Закона за
                    задълженията и договорите, нямат право да вземат участие в
                    наддаването.
                  </p>
                  <p>
                    Когато имотът е купен от лице, което не е имало право да
                    наддава, проданта е недействителна.
                  </p>
                  <p>
                    В случая по ал. 2 внесената от купувача сума се задържа за
                    удовлетворение на вземанията по изпълнителното дело, а
                    имотът по искане на който и да е от взискателите може отново
                    да се изнесе на продан.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3> Неизвършване на проданта при плащане на дълга </h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Ако до изтичането на срока за подаване на писмените
                    наддавателни предложения длъжникът внесе всичко по
                    предявените срещу него изпълнителни листове и разноските по
                    изпълнителното дело, проданта не се извършва.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3> Обявяване на купувач </h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    В началото на работния ден след изтичането на срока за
                    подаване на писмени наддавателни предложения на определеното
                    място в сградата на районния съд съдебният изпълнител в
                    присъствието на явилите се наддавачи обявява постъпилите
                    наддавателни предложения, за което съставя протокол. В
                    протокола се вписват наддавачите и наддавателните
                    предложения по реда на отварянето на пликовете. За купувач
                    на имота се смята този наддавач, който е предложил
                    най-висока цена. Ако най-високата цена е предложена от
                    повече от един наддавач, купувачът се определя от съдебния
                    изпълнител чрез жребий в присъствието на явилите се
                    наддавачи. Обявяването на купувача се извършва от съдебния
                    изпълнител в протокола, който се подписва от него.
                  </p>
                  <p>
                    Ако при обявяването на купувача някой от явилите се
                    наддавачи устно предложи цена, по-висока с размера на един
                    задатък, съдебният изпълнител отразява предложението в
                    протокола и след като наддавачът го подпише, съдебният
                    изпълнител пита три пъти има ли желаещи да предложат
                    по-висока цена с размера на още един задатък. Ако постъпи
                    предложение, то се отразява в протокола и наддавачът го
                    подписва. След изчерпване на предложенията за купувач на
                    имота се обявява този наддавач, който е предложил най-висока
                    цена.
                  </p>
                  <p>
                    Купувачът е длъжен в двуседмичен срок от обявяването му за
                    купувач да внесе предложената от него цена, като приспадне
                    внесения задатък.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3> Следващ купувач </h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>Ако цената не бъде внесена в срока по чл. 492, ал. 3:</p>
                  <p>
                    1. внесеният от наддавача задатък служи за удовлетворение на
                    взискателите, а когато наддавачът е взискател, вземането му
                    се намалява с размера на един задатък;
                  </p>
                  <p>
                    2. съдебният изпълнител обявява за купувач на имота
                    наддавача, който е предложил следващата най-висока цена; ако
                    този наддавач не внесе предложената от него цена в
                    едноседмичен срок от обявяването му за купувач, съдебният
                    изпълнител обявява за купувач наддавача, направил следващото
                    по ред наддавателно предложение и постъпва така до
                    изчерпване на всички наддавачи, предложили цена, равна на
                    началната; наддавачът, който е бил обявен за купувач, но не
                    внесе в срок предложената цена, отговаря по т. 1; след
                    плащане на цената от наддавач, обявен за купувач, внесеният
                    задатък се връща на наддавачите, които не са били обявени за
                    купувачи.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3> Нова продан </h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Ако не са се явили наддавачи или не са били направени
                    валидни наддавателни предложения, или ако купувачът не е
                    внесъл цената и имотът не е бил възложен по реда на чл. 493,
                    т. 2, взискателят има право в едноседмичен срок от
                    съобщението да поиска да се извърши нова продан.
                  </p>
                  <p>
                    Новата продан се извършва по правилата за първата продан. Тя
                    започва не по-рано от един месец от приключването на първата
                    продан при начална цена, равна на 90 на сто от началната
                    цена по първата продан. Ако и при тази продан имотът не бъде
                    продаден и в едноседмичен срок от съобщението не бъде
                    поискано определянето на нова начална цена, имотът се
                    освобождава от изпълнение и възбраната се заличава по искане
                    на съдебния изпълнител.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3> Изплащане на цената от взискател </h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Взискателят, обявен за купувач на имот, е длъжен в
                    двуседмичен срок от влизането в сила на разпределението да
                    внесе сумата, необходима за изплащане на съразмерните части
                    от вземанията на другите взискатели, или сумата, с която
                    цената надминава неговото вземане, когато няма други
                    взискатели. Ако не внесе тази сума, той отговаря за вредите
                    и за разноските по проданта, а за имота се прилагат
                    съответно чл. 493, т. 2 и чл. 494, ал. 2.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3> Постановление за възлагане </h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Когато лицето, обявено за купувач по реда на чл. 492 - 494,
                    внесе в срок дължимата сума, съдебният изпълнител му възлага
                    имота с постановление.
                  </p>
                  <p>
                    От деня на влизане в сила на постановлението за възлагане
                    купувачът придобива всички права, които длъжникът е имал
                    върху имота. Правата, които трети лица са придобили върху
                    имота, не могат да бъдат противопоставени на купувача, ако
                    тези права не могат да се противопоставят на взискателите.
                  </p>
                  <p>
                    Ако възлагането не бъде обжалвано, действителността на
                    продажбата може да бъде оспорвана по исков ред само при
                    нарушаване на чл. 490 и при невнасяне на цената. В последния
                    случай купувачът може да отклони уважаването на иска, ако
                    внесе дължимата сума заедно с лихвите, начислени от деня на
                    обявяването му за купувач
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3>Обявяване на новата продан</h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Ако постановлението за възлагането бъде отменено или ако
                    продажбата бъде обявена за недействителна по чл. 496, ал. 3,
                    новата продан се извършва след ново обявяване.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3>Въвод на купувача</h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Купувачът се въвежда във владение на имота от съдебния
                    изпълнител въз основа на влязлото в сила постановление за
                    възлагане. Купувачът представя удостоверения за платените
                    такси за прехвърляне на имота и за вписване на
                    постановлението за възлагане.
                  </p>
                  <p>
                    Въводът се извършва срещу всяко лице, което се намира във
                    владение на имота. То може да се брани само с иск за
                    собственост.
                  </p>
                  <p>
                    Алинея 2 се прилага и спрямо лицата, вписали договори за
                    наем и аренда, след първата ипотека, както и всички
                    споразумения, с които се предоставя ползването и
                    управлението на имота.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3>Връщане на даденото при съдебно отстранение</h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Ако с влязло в сила решение бъде установено, че длъжникът не
                    е бил собственик на продадения имот, купувачът може да иска
                    внесената от него цена, ако тя още не е изплатена на
                    взискателите, а ако е била изплатена, той може да иска от
                    всеки от тях, както и от длъжника това, което е получил. И в
                    двата случая купувачът има право на лихвите и разноските за
                    своето участие в проданта. Той има право също да иска от
                    общината и държавата връщане на платените такси по
                    прехвърлянето.
                  </p>
                  <p>
                    За връщането на сумите по ал. 1 районният съдия по
                    местонахождението на имота издава изпълнителен лист въз
                    основа на разпределението и на удостоверенията по чл. 498,
                    ал. 1, ако лицата, срещу които се издава листът, са били
                    привлечени в делото, по което е постановено решението. Ако
                    внесената от купувача сума не е изплатена, тя му се връща с
                    платежно нареждане на съдебния изпълнител.
                  </p>
                  <p>
                    Когато имотът е бил възложен на взискател, той запазва
                    вземането си срещу длъжника и има право да иска по реда на
                    ал. 2 посочените в ал. 1 суми без разноските за своето
                    участие в проданта
                  </p>
                </div>
              </div>
            </div>
            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3>Продан на съсобствен имот</h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    Когато изпълнението бъде насочено върху съсобствен имот за
                    дълг на някой от съсобствениците, имотът се описва изцяло,
                    но се продава само идеалната част на длъжника.
                  </p>
                  <p>
                    Имотът може да бъде продаден и изцяло, ако останалите
                    съсобственици се съгласят с това писмено.
                  </p>
                </div>
              </div>
            </div>

            <div className='app__blanks-section__content-item'>
              <div
                className='app__blanks-section__content-item__header'
                onClick={handleClick}
              >
                <h3>Продан на ипотекиран имот</h3>
              </div>
              <div className='app__blanks-section__content-item__body'>
                <div className='app__blanks-section__content-item__body-text'>
                  <p>
                    При продан на ипотекиран имот, която се извършва не по
                    вземането на ипотекарния кредитор, съдебният изпълнител му
                    изпраща съобщение за насрочването на описа и проданта.
                  </p>
                  <p>
                    В случаите по чл. 494 и 495 ипотекарният кредитор може да
                    участва наравно с другите кредитори.
                  </p>
                </div>
              </div>
            </div>            
          </div>
        </div>        
      </div>      
      <Footer />
    </>
  );
};

export default BlanksPage;
