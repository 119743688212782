import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import {MdOutlineKeyboardDoubleArrowRight} from 'react-icons/md';

import { SubHeading } from '../../components';
import './Tariff.css';
import AOS from "aos";
import "aos/dist/aos.css";

const Tariff = () => { 
  // useEffect(() => {
  //   AOS.init({ duration: 2000, offset: 20 });
  
  //   AOS.refresh();
  // }, []);
  return (
    <div className='app__tariff app__bg flex__center section__padding' id='tariff'>
      <div data-aos="fade-down"  className='app__tariff-title'>
        <SubHeading title='Тарифа' />
        <h1 className='headtext__cormorant'> Тарифа</h1>
        <p className='p__opensans'>За таксите и разноските към Закона за частните съдебни изпълнители</p>
      </div>

      <div className='app__tariff-content'>        
        <Link  to='/tariff/fees01'>
      <div data-aos="flip-down" className='app__tariff-content_item'> 
          <h2>Обикновени такси</h2> <MdOutlineKeyboardDoubleArrowRight className='app__tariff-icon'/>
      </div>      
      </Link>
      <Link  to='/tariff/fees02'>
      <div data-aos="flip-down" className='app__tariff-content_item'> 
          <h2>Пропорционални такси</h2> <MdOutlineKeyboardDoubleArrowRight className='app__tariff-icon'/>
      </div>      
      </Link>
      <Link to='/tariff/fees03'>
      <div data-aos="flip-down" className='app__tariff-content_item'> 
          <h2>Допълнителна такси</h2> <MdOutlineKeyboardDoubleArrowRight className='app__tariff-icon'/>
      </div>      
      </Link>
      <Link  to='/tariff/fees04'>
      <div data-aos="flip-down" className='app__tariff-content_item'> 
          <h2>Авансови такси</h2> <MdOutlineKeyboardDoubleArrowRight className='app__tariff-icon'/>
      </div>      
      </Link>
      <Link to='/tariff/fees05'>
      <div data-aos="flip-down" className='app__tariff-content_item'> 
          <h2>Субсидирани такси</h2> <MdOutlineKeyboardDoubleArrowRight className='app__tariff-icon'/>
      </div>      
      </Link>
      <Link to='/tariff/fees06'>
      <div data-aos="flip-down"  className='app__tariff-content_item'> 
          <h2>Допълнителни разноски</h2> <MdOutlineKeyboardDoubleArrowRight className='app__tariff-icon'/>
      </div>      
      </Link>  
      <Link to='/tariff/fees07'>
      <div data-aos="flip-down"className='app__tariff-content_item'> 
          <h2>Плащане на таксите</h2> <MdOutlineKeyboardDoubleArrowRight className='app__tariff-icon'/>
      </div>      
      </Link>       
      </div>    
    </div>
  )
}

export default Tariff;
