import React from 'react';
import './Banner.css';


const Banner = ({image, bg}) => {
  return (
    <div className={`app__banners ${bg}`}>
        <div data-aos="zoom-out" className='app__banners-content'>
          <img src={image} alt='image_banner' />
        </div>
      </div>
  )
}

export default Banner;
