import React, { useState, useEffect, useRef } from "react";
import emailjs from '@emailjs/browser';

import "./ContactForm.css";


const ContactForm = () => {
  const [inputFields, setInputFields] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false); 
  const form = useRef();

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputFields.name) {
      errors.name = "Required";
    } else if (inputFields.name.length < 3) {
      errors.name = "Too short.The name must be more than 3 characters long";
    } else if (inputFields.name.length > 50) {
      errors.name = "Too long. The name must be less than 50 characters long";
    }
    if (!inputFields.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(inputFields.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!inputFields.subject) {
      errors.subject = "Required";
    } else if (inputFields.subject.length < 3) {
      errors.subject =
        "Too short. The subject must be more than 3 characters long";
    } else if (inputFields.subject.length > 100) {
      errors.subject =
        "Too long.The subject must be less than 100 characters long";
    }

    if (!inputFields.message) {
      errors.message = "Required";
    } else if (inputFields.message.length < 10) {
      errors.message =
        "Too short.The message must be more than 10 characters long";
    } else if (inputFields.message.length > 500) {
      errors.message =
        "Too long.The message must be less than 500 characters long";
    }
    return errors;
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };



const handleSubmit=(e)=>{  
  e.preventDefault(); 
 
  setErrors(validateValues(inputFields));   
  if (Object.keys(validateValues(inputFields)).length === 0 ) { 
    setSubmitting(true);
  
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, form.current, process.env.REACT_APP_PUBLIC_KEY)
    .then((result) => {
        console.log(result.text);         
        setInputFields({
                name: "",
                email: "",
                subject: "",
                message: "",
              });   
              setTimeout(() => setSubmitting(false), 2000);
    }, (error) => {
        console.log(error.text);
    }); 
   
  }else {   
    console.log("Something wrong");
    return; 
  } 
}


  return (
    <div className='app__contactform-container'>
      {Object.keys(errors).length === 0 && submitting ? (
       <div className="success-container slide-bottom">
        <span className='success'>Благодарим, че се свързахте с нас! Съобщението Ви беше изпратено успешно ✓</span>
       </div>        
      ) : null}
      <form
      ref={form} onSubmit={handleSubmit}       
        className='app__contactform-container__form app__bgblue'
      >
        <div>
          {errors.name ? <p className='error'>{errors.name}</p> : null}
          <input
            type='text'
            name='name'
            placeholder='Вашето име'
            value={inputFields.name}
            onChange={handleChange}
          ></input>
        </div>
        <div>
          {errors.email ? <p className='error'>{errors.email}</p> : null}
          <input
            type='email'
            name='email'
            placeholder='Вашият Email'
            value={inputFields.email}
            onChange={handleChange}
          ></input>
        </div>

        <div>
          {errors.subject ? <p className='error'>{errors.subject}</p> : null}
          <input
            type='text'
            name='subject'
            placeholder='Относно'
            value={inputFields.subject}
            onChange={handleChange}
          ></input>
        </div>

        <div>
          {errors.message ? <p className='error'>{errors.message}</p> : null}
          <textarea
            rows={3}
            name='message'
            placeholder='Съобщение'
            value={inputFields.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <button className='custom__button' type='submit'>
          Изпрати
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
