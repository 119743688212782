import React from 'react'

const Fees06 = () => {
  return (
    <div className='fade-in'>
      <p className='p__custom'> 31. Допълнителни разноски са разходите за:</p>
      <p className='p__custom'>а) (изм. – ДВ, бр. 24 от 2013 г.) получаване на информация и документи, свързани с длъжника и/или неговото имущество;</p>
      <p className='p__custom'>б) банкови такси и комисиони;</p>
      <p className='p__custom'>в) осигуряване на достъп, отваряне и затваряне на помещения;</p>
      <p className='p__custom'>г) транспорт, пренасяне и охрана на иззети вещи;</p>
      <p className='p__custom'>д) вещи лица;</p>
      <p className='p__custom'>е) пазачи;</p>
      <p className='p__custom'>ж) преводачи и тълковници;</p>
      <p className='p__custom'>з) премахване, унищожаване и запечатване на вещи;</p>
      <p className='p__custom'>и) придвижване на частния съдебен изпълнител извън населеното място, където е неговата кантора – по 0,50 лв. на километър, но не повече от 30 лв. за едно действие, независимо дали то е изпълнено с едно или повече посещения;</p>
      <p className='p__custom'></p>
      <p className='p__custom'>к) (нова – ДВ, бр. 24 от 2013 г.) заплащане на пощенски и/или куриерски услуги.
</p>
      <p className='p__custom'><i>Забележка.</i> (изм. – ДВ, бр. 24 от 2013 г.) Допълнителните разноски са за сметка на длъжника, когато за тях е издаден документ по Закона за счетоводството.</p>
   </div>
  )
}

export default Fees06

