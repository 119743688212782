import React from 'react'

const Fees05 = () => {
  return (
    <div className='fade-in'>
      <p className='p__custom'>30. Субсидирани са авансовите такси за вземане за издръжка, за предаване на дете и за вземане по трудово правоотношение.
</p>
      <p className='p__custom'><i>Забележки:</i></p>
      <p className='p__custom'>1. Таксите по този раздел се предявяват от частния съдебен изпълнител за изплащане от бюджета на съответния окръжен съд с писмено заявление, в което се посочват: трите имена и регистрационният номер на частния съдебен изпълнител, адресът на кантората, номерът на изпълнителното дело, основанието и размерът на дължимата субсидирана такса и банковите сметки, по които да се извърши преводът, номерът, страните и предметът на делото, по което е издаден изпълнителният лист, съдът, който го е издал.</p>
      <p className='p__custom'>2. Частният съдебен изпълнител възстановява по бюджета на съда субсидираната такса, след като бъде събрана от длъжника.</p>    
    </div>
  )
}

export default Fees05
