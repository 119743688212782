import React from 'react'

const Fees02 = () => {
  return (
    <div className='fade-in'>
      <p className='p__custom'> 20. (изм. – ДВ, бр. 24 от 2013 г.) За извършване на опис на движими и недвижими имущества, включително на налични ценни книжа, се събира такса в размер 1,5 на сто върху по-малката сума от цената на описаната вещ/ценните книжа и от паричното вземане, но не по-малко от 50 лв.</p>
      <p className='p__custom'><i>Забележки:</i></p>
      <p className='p__custom'>1. При преминаване на изпълнението от едни вещи върху други, независимо от това, дали са движими или недвижими, нова такса не се събира, ако не е бил извършен опис.</p>
      <p className='p__custom'>2. Таксата се събира и при опис на вещи по реда на чл. 557 ГПК.</p>
      <p className='p__custom'>3. За извършване на опис на имущество в срока за доброволно изпълнение такса не се събира.</p>
      <p className='p__custom'>21. За принудително отнемане и предаване на движими вещи, включително на заложено имущество по ЗОЗ, се събира такса в размер 2 на сто върху цената на вещите, но не по-малко от 50 лв.</p>
      <p className='p__custom'><i>Забележка.</i> След публична продан или продажба таксата е за сметка на купувача.</p>
      <p className='p__custom'>22. За въвеждане във владение на недвижим имот се събира такса в размер 1 на сто върху цената на имота, но не по-малко от 200 лв.</p>
      <p className='p__custom'><i>Забележки:</i></p>
      <p className='p__custom'>1. Когато цената на имота не е посочена в изпълнителния лист, таксата се събира върху данъчната му оценка по Закона за местните данъци и такси.</p>
      <p className='p__custom'>2. Таксата за въвеждане във владение след публична продан е за сметка на купувача.</p>
      <p className='p__custom'>3. За въвеждане във владение на семейно жилище се събира такса 50 лв.</p>
      <p className='p__custom'>23. За изготвяне на постановление за възлагане на недвижим имот се събира такса в размер 1,5 на сто върху продажната цена, но не по-малко от 50 и не повече от 3000 лв.</p>
      <p className='p__custom'><i>Забележка.</i> Таксата е за сметка на купувача.</p>
      <p className='p__custom'>24. За извършване на публична продан на делбен имот или на заложено имущество по реда на ЗОЗ или на ГПК се събира такса в размер 2 на сто върху продажната цена, но не по-малко от 50 лв.</p>
      <p className='p__custom'><i>Забележка.</i> При делба таксата е за сметка на съделителите съобразно техните дялове.</p>
      <p className='p__custom'>25. За пазене на описано или дадено в обезпечение имущество се събира такса, както следва:</p>
      <p className='p__custom'>а) когато имуществото не носи доход – от 50 до 100 лв. за календарен месец;</p>
      <p className='p__custom'>б) когато освен пазене се осъществява и управление на имуществото – 20 на сто върху брутния месечен доход от имуществото за периода на пазенето, но не по-малко от 50 лв.</p>
      <p className='p__custom'>26. (изм. – ДВ, бр. 24 от 2013 г.) За изпълнение на парично вземане се събира такса върху събраната сума, както следва:</p>
      <p className='p__custom'>а) до 100 лв. – 10 лв.;</p>
      <p className='p__custom'>б) от 100 до 1000 лв. – 10 лв. + 10 на сто за горницата над 100 лв.;</p>
      <p className='p__custom'>в) от 1000 до 10 000 лв. – 100 лв. + 8 на сто за горницата над 1000 лв.;</p>
      <p className='p__custom'>г) от 10 000 до 50 000 лв. – 820 лв. + 6 на сто за горницата над 10 000 лв.;</p>
      <p className='p__custom'>д) от 50 000 до 100 000 лв. – 3220 лв. + 4 на сто за горницата над 50 000 лв.;</p>
      <p className='p__custom'>е) над 100 000 лв. – 5220 лв. + 2 на сто за горницата над 100 000 лв.</p>
      <p className='p__custom'><i>Забележки:</i></p>
      <p className='p__custom'>1. При частично събиране на паричното вземане таксата се определя за целия дълг, но се събира част, съответстваща на събраната сума. Таксата, събрана при частичните плащания, трябва да е равна на изчислената върху общо събраната сума.</p>
      <p className='p__custom'>2. При изпълнение на вземане за периодични платежи, включително за издръжка, таксата се събира еднократно върху сбора от изплатените суми на всеки 6 месеца, с изключение на случаите на погасяване на задълженията за минало време.</p>
      <p className='p__custom'>3. Таксата върху постъпилите суми при присъединяване на взискатели се събира само от съдебния изпълнител, събрал сумата на присъединените взискатели.</p>
      <p className='p__custom'>
4. В размера на паричното вземане не се включват авансовите такси.</p>
      <p className='p__custom'>5. От таксата за изпълнение на парично вземане се приспадат всички пропорционални такси за сметка на длъжника или взискателя, надвишаващи една десета от вземането.</p>  
      <p className='p__custom'>6. (нова – ДВ, бр. 63 от 2014 г., в сила от 01.08.2014 г., спряно изпълнение с Определение № 10729 от 29.08.2014 г. на ВАС – ДВ, бр. 90 от 2014 г., в сила от 31.10.2014 г., обявена за нищожна с Решение № 13014 от 2 декември 2015 г. на ВАС – ДВ, бр. 100 от 2015 г., в сила от 18.12.2015 г.)</p>
      <p className='p__custom'>27. За изпълнение на обезпечителни мерки извън случаите по раздел I се събират таксите, предвидени за съответните действия в този раздел.</p>
      <p className='p__custom'>27а. (нова – ДВ, бр. 24 от 2013 г.) Максималният размер на пропорционалните такси не може да надвишава:</p>
      <p className='p__custom'>1. една десета от вземането – в случаите по т. 20 и 26;</p>
      <p className='p__custom'>2. една десета от цената на имота/вещите – в случаите по т. 21 до 24 включително.</p>
    </div>
  )
}

export default Fees02
