import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import { images } from "../../constants";
import { SubHeading } from "../../components";
import "./AboutUs.css";
import AOS from "aos";
import "aos/dist/aos.css";

const AboutUs = () => {
  return (
    <div className='app__aboutus  flex-center section__padding' id='about'>
      <div className="app__aboutus-info">
         <section className='app__aboutus-section'>
        <div className='app__aboutus-content'>
          <SubHeading title='За нас' />
          <h1 data-aos="fade-right" className='headtext__cormorant'>ЧСИ</h1>        
          <div data-aos="fade-left">        
             <p className='p__opensans'> Георги Каримов</p>
             <br/>
          <p className='p__opensans'>
          Действащ частен съдебен изпълнител от 25.05.2006 г.
          </p>
          <p className='p__opensans'>
        с район на действие - Софийски окръжен съд
          </p>
          </div>         
        </div>
        <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='app__aboutus-image'>
          <img src={images.man} alt='image__man' />
        </div>
      </section>
      <section className='app__aboutus-section'>
        <div className='app__aboutus-content'>
          <h1 data-aos="fade-right" className='headtext__cormorant'>Екип</h1>
          <div data-aos="fade-left">
            <p className='p__opensans'>Мариана Жишкина - секретар</p>           
          <p className='p__opensans'>Наталия Зетова - деловодител</p>
          <p className='p__opensans'>Иво Манчев - призовкар</p>
          </div>          
        </div>
        <div data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000" className='app__aboutus-image'>
          <img src={images.office2} alt='image__office' />
        </div>
      </section>
      </div>
     
      <div className='app__aboutus-nav'>
        <Link to='/about'>
          <button data-aos="fade-up" type='button' className='custom__button'>
            Научете повече
          </button>
        </Link>
      </div>
    </div>
  );
};

export default AboutUs;
