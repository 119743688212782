import React from "react";
import "./Contacts.css";
import { ContactForm, SubHeading } from "../../components";

const Contacts = () => {
  return (
    <div className='app__contacts app__bg2 flex__center section__padding' id="contacts">
      <div className='app__contacts-title'>
        <SubHeading title='Контакти' />
        <h1 data-aos="fade-right" className='headtext__cormorant'> Свържете се с нас</h1>
      </div>
      <div className='app__contacts-content'>
        <ContactForm />

        <div className='app__contacts-map' id="address">
          <iframe title="google__map"
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2085.4195694641403!2d23.55879377901206!3d42.337648509229574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14ab253da9d9e0f1%3A0xbd7b33a2089fb686!2z0JPQtdC-0YDQs9C4INCd0LjQutC-0LvQvtCyINCa0LDRgNC40LzQvtCyICjRh9Cw0YHRgtC10L0g0YHRitC00LXQsdC10L0g0LjQt9C_0YrQu9C90LjRgtC10Lsp!5e0!3m2!1sbg!2sbg!4v1690894013921!5m2!1sbg!2sbg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">'
            width='300'
            height='300'
            style={{ border: "0" }}
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
