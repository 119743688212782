import React from 'react';
import { BankAccounts, Navbar } from '../../components';
import { Sales, Footer, Contacts } from '../../container';

const SalesPage = () => {
  return (
    <div>
      <Navbar/>
      <Sales/>
      <BankAccounts/>
      <Contacts/>
      <Footer/>
    </div>
  )
}

export default SalesPage;
