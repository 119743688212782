import React from 'react';
import { Banner, SubHeading } from '../../components';
import { images } from '../../constants';

import './Sphere.css';

const Sphere = () => {

  const handleClick = (e) => {
    const elementHeader = e.target;
    const elementBody = elementHeader.nextElementSibling;
    console.log(elementHeader);
    if (!elementHeader.classList.contains("active")) {
      elementHeader.classList.add("active");
      elementBody.style.maxHeight = elementBody.scrollHeight + "px";
    } else {
      elementHeader.classList.remove("active");
      elementBody.style.maxHeight = 0;
    }
  };
  // const handleClick = (e) => { 

  //     const element = e.target.nextElementSibling;       
  //     if (element.style.display === 'none') {
  //       element.style.display = 'block'
  //     } else {
  //       element.style.display = 'none'
  //     }
  //   }
  return (
    <div className='app__sphere app__bg3'>
      <Banner image={images.services} bg="app__bg3" />
      <div className='app__sphere-title'>
        <SubHeading title='Сфери на дейност' />
        <h1 data-aos="zoom-in" className='headtext__cormorant'>Сфери на дейност и услуги</h1>
      </div>

      <div className='app__sphere-content section__padding'>
        <div className='app__sphere-wrapper'>

          <div className='app__sphere-content__section'>

            <div data-aos="fade-right" className='app__sphere-content__section-item'>
              <div
                className='app__sphere-content__section-item__header'
                onClick={handleClick}
              >
                <h4> Принудително изпълнение на съдебни актове по реда на ГПК </h4>
              </div>
              <div className='app__sphere-content__section-item__body'>
                <div className='app__sphere-content__section-item__body-text'>
                  <ul>
                    <li>Събиране на парични вземания;</li>
                    <li>Налагане възбрани върху недвижими имоти;</li>
                    <li>Налагане запори върху движими вещи, трудови; възнаграждение и други парични вземания на задълженото лице от трети лица, дялове и акции в търговски дружества;</li>
                    <li>Извършване описи и публични продажби на имуществото на длъжника;</li>
                    <li>Предаване владението на движими вещи и на недвижими имоти;</li>
                    <li>Изпълнение на съдебни решения, с които длъжникът е осъден да извърши определени действия;</li>
                    <li>Публична продан на делбени имоти.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div data-aos="fade-right" className='app__sphere-content__section-item'>
              <div
                className='app__sphere-content__section-item__header'
                onClick={handleClick}
              >
                <h4> Принудително изпълнение на актове на административните съдилища по реда на АПК </h4>
              </div>
              <div className='app__sphere-content__section-item__body'>
                <div className='app__sphere-content__section-item__body-text'>
                  <ul>
                    <li>Събиране на публични вземания по реда на ДОПК по възлагане на държавен орган или община;</li>
                    <li>Събиране на местни данъци и такси.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div data-aos="fade-right" className='app__sphere-content__section-item'>
              <div
                className='app__sphere-content__section-item__header'
                onClick={handleClick}
              >
                <h4> Изпълнение на допуснати от съда обезпечителни мерки </h4>
              </div>
              <div className='app__sphere-content__section-item__body'>
                <div className='app__sphere-content__section-item__body-text'>
                  <ul>
                    <li>Запори върху движими вещи и вземания;</li>
                    <li>Други мерки, постановени от съда.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div data-aos="fade-right" className='app__sphere-content__section-item'>
              <div
                className='app__sphere-content__section-item__header'
                onClick={handleClick}
              >
                <h4> Други услуги </h4>
              </div>
              <div className='app__sphere-content__section-item__body'>
                <div className='app__sphere-content__section-item__body-text'>
                  <ul>
                    <li>Пълно проучване имуществото на длъжника по възлагане от взискателя (кредитора) по образуваните изпълнителни дела;</li>
                    <li>Проучване имуществото на задължени лица във връзка с производстото по принудително изпълнение чрез справки във всички публични регистри на имущество;</li>
                    <li>Пазене на даденото в обезпечение имущество по възлагане на страните;</li>
                    <li>Посредничество за постигане на спогодба между страните по изпълнителните дела при възлагане;</li>
                    <li>Връчване на съдебни призовки, съобщения и книжа в исковото производство (по искане на страната и разпореждане на съда);</li>
                    <li>Изпълнения на непарични вземания на кредитори;</li>
                    <li>Изпълнение по реда на Закона за особените залози по възлагане от обезпечения кредитор (продажба на заложено имущество по реда на ЗОЗ или ГПК и изпълняване функциите на депозитар);</li>
                    <li>Запечатване на помещения, оборудване, превозни средства и др.;</li>
                    <li>Определяне най-подходящия и ефективен начин за изпълнение (служебно движение на делото) по възлагане от взискателя по образуваните изпълнителни дела;</li>
                  </ul>
                </div>
              </div>
            </div>
            <div data-aos="fade-right" className='app__sphere-content__section-item'>
              <div className='app__sphere-content__section-item__header' onClick={handleClick}>
                <h4>Издаване на справки от регистъра на длъжниците за наличието или липсата на заведени изпълнителни дела срещу молителя при всички ЧСИ на територията на страната</h4>
              </div>
              <div className='app__sphere-content__section-item__body'>
                <div className='app__sphere-content__section-item__body-text'>
                  <p>Повече информация можете да получите на страницата на Централен регистър на длъжниците на адрес: <a href="https://newregistry.bcpea.org/pages/procedure1.html" target="_blank" rel='noreferrer'>https://newregistry.bcpea.org/pages/procedure1.html</a></p>
                </div>
              </div>
            </div>
          </div>



          <div className='app__sphere-content__section-c'>           
            <div data-aos="fade-left" className='app__sphere-content__section-c-item'>
              <h4>Полезни връзки</h4>
              <ul>
                <li>
                  <p>Камара на частните съдебни изпълнители</p>
                  <span><a href="https://www.bcpea.org/" target="_blank" rel='noreferrer'>https://www.bcpea.org/</a></span>
                </li>
                <li>
                  <p>Централен регистър на длъжниците</p>
                  <span><a href="https://newregistry.bcpea.org/" target="_blank" rel='noreferrer'>https://newregistry.bcpea.org/pages/procedure1.html</a></span>
                </li>
                <li>
                  <p>Министерство на правосъдието</p>
                  <span><a href=" https://justice.government.bg/" target="_blank" rel='noreferrer'> https://justice.government.bg/</a></span>
                </li>
                <li>
                  <p>Международен съюз на съдебните изпълнители</p>
                  <span><a href="https://www.uihj.com/" target="_blank" rel='noreferrer'> https://www.uihj.com/</a></span>
                </li>
                <li>
                  <p>Висш съдебен съвет</p>
                  <span><a href="https://vss.justice.bg/" target="_blank" rel='noreferrer'> https://vss.justice.bg/</a></span>
                </li>
                <li>
                  <p>Софийски окръжен съд</p>
                  <span><a href="https://sofia-os.justice.bg/" target="_blank" rel='noreferrer'> https://sofia-os.justice.bg/</a></span>
                </li>
                <li>
                  <p>Върховен касационен съд</p>
                  <span><a href="https://www.vks.bg/" target="_blank" rel='noreferrer'> https://www.vks.bg/</a></span>
                </li>
                <li>
                  <p>Нотариална камара</p>
                  <span><a href="https://www.notary-chamber.bg/bg" target="_blank" rel='noreferrer'> https://www.notary-chamber.bg/bg</a></span>
                </li>
                <li>
                  <p>Агенция по геодезия, картография и кадастър</p>
                  <span><a href="https://www.cadastre.bg/frontpage" target="_blank" rel='noreferrer'> https://www.cadastre.bg/frontpage</a></span>
                </li>
                <li>
                  <p>Правен портал Lex</p>
                  <span><a href="https://lex.bg/" target="_blank" rel='noreferrer'> https://lex.bg/</a></span>
                </li>
                <li>
                  <p>Лихвен калкулатор</p>
                  <span><a href="https://www.calculator.bg/1/lihvi_zadaljenia.html" target="_blank" rel='noreferrer'> https://www.calculator.bg/1/lihvi_zadaljenia.html</a></span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sphere
