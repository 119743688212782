import React from "react";

import { DistrictCourts, SubHeading, Banner } from "../../components";
import { images } from '../../constants';

import "./Range.css";

const Range = () => {


  return (
    <div className='app__range '>
      <Banner image={images.services} bg="app__bg3"/>
      <div className="app__range-container section__padding">
        <div className="app__range-title">
          <SubHeading title='Район на действие' />
          <h1 data-aos="zoom-in" className='headtext__cormorant'>
            Район на действие - <a href="https://sofia-os.justice.bg/" target="blank" rel='noreferrer'><span>Софийски окръжен съд </span></a>
          </h1>
        </div>
        <div className='app__range-info'>
          <p className='p__opensans'>
            Териториалната компетентност на частния съдебен изпълнител се определя от района на съответния окръжен съд.
            Частен съдебен изпълнител Георги Каримов осъществява принудително изпълнение с район на действие Окръжен съд – София, който съвпада с административните граници на Софийска област.
          </p>
        </div>

        <div data-aos="fade-left" className='app__range-content'>
          <div className='app__range-content__map'>
            <iframe
              title='google__map'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d446558.7767196212!2d23.13291528618323!3d42.66836216826443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40aa7b33d78c9531%3A0x300a01269bf4d20!2z0KHQvtGE0LjQudGB0LrQsCDQvtCx0LvQsNGB0YI!5e0!3m2!1sbg!2sbg!4v1691315166018!5m2!1sbg!2sbg" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade'

              style={{ border: "0" }}
              allowFullScreen=''
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            ></iframe>
          </div>

          <DistrictCourts />
        </div>
        <div data-aos="fade-right" className='app__range-info'>
          <p className='p__opensans'>
            Молбата за изпълнение се подава до съдебния изпълнител, в чийто район се намират:
          </p>
          <br />
          <p className='p__opensans'>
            1. недвижимите имоти, върху които е насочено изпълнението;
          </p>
          <p className='p__opensans'>
            2. движимите вещи, когато следва да се извърши тяхното предаване от длъжника;
          </p>
          <p className='p__opensans'>
            3. постоянният или настоящият адрес на взискателя или длъжника – по избор на взискателя, по вземане за издръжка, възнаграждение и обезщетение за работа или обезщетение за вреди от непозволено увреждане;
          </p>
          <p className='p__opensans'>
            4. местоизпълнението на задълженията за действие или бездействие, когато се иска изпълнение на такива задължения;
          </p>
          <p className='p__opensans'>
            5. постоянният или настоящият адрес или седалището на длъжника;
          </p>
          <p className='p__opensans'>
            6. имуществото на длъжника, върху което е насочено изпълнението, когато той няма постоянен адрес или седалище на територията на Република България.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Range;
