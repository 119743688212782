import React from 'react';

import { Navbar } from '../../components';
import {Range, Footer} from '../../container';


const RangePage = () => {
  return (
    <div>
      <Navbar/>
      <Range/>
      <Footer/>
    </div>
  )
}

export default RangePage
