import React from 'react';
import './Loader.css';
import { images } from '../../constants';

const Loader = () => {
  return (
    <div className="loader-container">      
         <img src={images.logowhite} alt="spinner" /> 
        <h1 className='p__opensans'>Loading...</h1>      
      </div>
  )
}
export default Loader
