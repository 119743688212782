import React, {useState, useEffect} from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";

import {Home, TariffPage, SalesPage, BlanksPage, ComingSoon, NotFound, RangePage, PowersPage, SpherePage,ContactsPage, ServicesPage, AboutPage} from './pages';
import { BackToTop, Loader } from "./components";
import ScrollToTop from "./hooks/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1600);
  }, []);
if(loading){
  return  <Loader />
}
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutPage />} /> 
        <Route path='/services' element={<ServicesPage />} />      
        <Route path='/tariff/*' element={<TariffPage />} />
        <Route path='/sales' element={<SalesPage />} />
        <Route path='/contacts' element={<ContactsPage />} />
        <Route path='/blanks' element={<BlanksPage />} /> 
        <Route path='/range' element={<RangePage />} />    
        <Route path='/powers' element={<PowersPage />} />   
        <Route path='/sphere' element={<SpherePage />} />      
        <Route path='*' element={<NotFound />} />
        <Route path='/coming-soon' element={<ComingSoon />} />       
      </Routes>
      <ScrollToTop />
      <BackToTop />
    </div>
  );
};

export default App;
