import React from 'react'

const Fees07 = () => {
  return (
    <div className='fade-in'>
    <p className='p__custom'>32. При възлагане на частен съдебен изпълнител от друг район да извърши действие по чл. 18, ал. 6 ЗЧСИ таксата, определена за съответното действие в тарифата, се получава от него.</p>
    <p className='p__custom'>33. Таксите, предвидени в тарифата, се плащат от взискателя при образуване на изпълнителното дело и преди извършване на съответното изпълнително действие.</p>
    <p className='p__custom'>34. Таксите и разноските по изпълнителното дело се плащат от взискателя и са за сметка на длъжника.</p> 
  </div>
  )
}

export default Fees07
