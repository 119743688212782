import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar } from '../../components';
import { Footer } from '../../container';
import './Comingsoon.css';

const ComingSoon = () => {
  return (
    <>
    <Navbar/>
      <div className='app__comingsoon app__bg3'>
      <h1 className='headtext__cormorant' style={{textAlign:"center"}}>Очаквайте скоро</h1>
      {/* <Countdown/> */}
      <Link to="/"><button type="button" className="custom__button">Обратно към начало</button></Link> 
    </div>
    <Footer/>
    </>
  
  )
}

export default ComingSoon;
