import React, {useEffect} from 'react';
import SubHeading from '../SubHeading/SubHeading';
import './BankAccounts.css'

const BankAccounts = () => {
    return (
        <div className='app__bankaccounts app__bg flex__center section__padding' id='bankovismetki'>
            <div  className='app__bankaccounts-title'>
                <SubHeading title="Банкови сметки" />
                <p data-aos="zoom-in" >Банкови сметки на ЧСИ Георги Каримов</p>
            </div>
            <div className='app__bankaccounts-content'>
                <div data-aos="fade-right" className='app__bankaccounts-content__item '>
                      <div className='app__bankaccounts-content__border'>
                        <h4>Специална сметка - за заплащане на задължения на длъжници</h4>
                    <p>IBAN: BG40 CECB 9790 5068 5619 00</p>
                    <p>BIC: CECBBGSF</p>
                    <p>Банка: „ЦЕНТРАЛНА КООПЕРАТИВНА БАНКА“ АД,</p>
                    <p>клон Самоков</p>
                </div>
                </div>
              <div data-aos="fade-left" className='app__bankaccounts-content__item '>
                  <div className='app__bankaccounts-content__border'>
                    <h4>Сметка за такси и разноски - за заплащане на такси от взискатели</h4>
                  <p>IBAN: BG96 CECB 9790 1068 5619 01</p>
                    <p>BIC: CECBBGSF</p>
                    <p>Банка: „ЦЕНТРАЛНА КООПЕРАТИВНА БАНКА“ АД,</p>
                    <p>клон Самоков</p>
                </div>
              </div>
              
            </div>
      
    </div>
  )
}

export default BankAccounts;
