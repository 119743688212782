import React from 'react';
import { BankAccounts, Navbar } from '../../components';
import './Home.css';
import { Header, AboutUs, Services, Footer, Tariff,Sales, Contacts } from '../../container';

const Home = () => {
 

  return (
    <>
      <Navbar />    
       <Header />
      <AboutUs />
      <div style={{height:"20px"}} className='app__bg2'></div>
      <Services/>
      <Tariff/>
      <Sales/>
      <BankAccounts/>
      <Contacts/>    
      <Footer />      
    </>
  )
}

export default Home;
