import React from 'react';
import { Footer, Contacts } from '../../container';
import{Navbar, Team}from '../../components';
// import ComingSoon from '../ComingSoon/ComingSoon'
const AboutPage = () => {
  return (
   
    <div>
      <Navbar/>
      <Team/>
      <Contacts />
      <Footer/>
    </div>
  )
}

export default AboutPage;
