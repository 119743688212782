import React from 'react'

const Fees04 = () => {
  return (
    <div className='fade-in'>
      <p className='p__custom'>  29. Взискателят внася авансово в размерите, определени в тарифата за съответното действие:</p>
      <p className='p__custom'>а) обикновените такси;</p>
      <p className='p__custom'>б) пропорционалните такси, с изключение на таксата по т. 26;</p>
      <p className='p__custom'>в) допълнителната такса;</p>
      <p className='p__custom'>г) допълнителните разноски.</p>
      <p className='p__custom'><i>Забележки:</i></p>
      <p className='p__custom'>1. Когато частният съдебен изпълнител е отишъл на мястото на принудителното изпълнение и то е отложено по искане на взискателя, внесената такса остава за негова сметка, а за продължаване на изпълнението се внася нова такса.</p>
      <p className='p__custom'>2. Не се дължат авансово субсидираните такси по раздел V.</p>    
    </div>
  )
}

export default Fees04
