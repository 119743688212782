import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import { MdKeyboardArrowRight,MdKeyboardArrowLeft } from 'react-icons/md';
import './Carousel.css';  
// import AOS from "aos";
// import "aos/dist/aos.css";

const Carousel = ({ slides }) => {  
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    // AOS.init({ duration: 2000 });
    // AOS.refresh();
    const timeout = setTimeout(
      () => setCurrent((current + 1 + length) % length),
      8000
    );
    return () => clearTimeout(timeout);
  }, [current, length]);

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }


  return (
    <div
      className='app__carousel'>
      <div className='app__carousel-inner'>
        {slides.map((slide, index) => {
          return (
            <div          
            className='app__carousel-item'
              key={index}
            >
              {index === current && (         
                <div className='app__carousel-wrapper fade-in-out'>            
                  <img  src={slide.image} alt='welcome-photos' />
                  <div className='app__carousel-wrapper__content'> 
                  <div className='app__carousel-wrapper__content-title'>
                     <h1 data-aos="zoom-in">{slide.title}</h1> 
                      <p data-aos="fade-right">{slide.tel}</p>
                      <p data-aos="fade-left">{slide.mobile}</p>
                    </div>
                    <Link to={slide.path}><button data-aos="fade-up"
                      data-aos-anchor-placement="center-bottom" className='button-sm'>{slide.text}</button></Link>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      
      <div className='app__carousel_arrows'>
      <MdKeyboardArrowLeft className="app__carousel_arrow-icon" onClick={prevSlide} />
          <MdKeyboardArrowRight className="app__carousel_arrow-icon" onClick={nextSlide} />      
      </div>
    </div>
  );
};

export default Carousel;



