import React from 'react';
import {images} from '../../constants';
import './SubHeading.css';

const SubHeading = ({title}) => (
  <div style={{ marginBottom:'1rem', display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
    <p className='p__cormorant' style={{letterSpacing:'5px'}}>{title}</p>
    <img src={images.line} alt='line' className='line__img'/>
  </div>
);

export default SubHeading;