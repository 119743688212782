import React from 'react';
import { images } from '../../constants';
import './Team.css';
import SubHeading from '../SubHeading/SubHeading';


const Team = () => {
    return (

        <div className='app__team flex__center app__bg2'>
            <div className='app__team-intro'>
                <div  className='app__team-intro__title'>
                    <SubHeading title="За нас" />
                    <h1 data-aos="fade-right" className="headtext__cormorant" >Екип</h1>
                </div>
                <div className='app__team-intro__image '>

                    <div data-aos="zoom-in"className='app_team-img'>
                        <img src={images.powers2} alt="" />
                        <h1  className='headtext__cormorant app__team-img__title ' >ЧСИ ГЕОРГИ КАРИМОВ</h1>
                    </div>
                </div>
            </div>


            <div data-aos="zoom-in" className='app__team-head section__padding'>
                <div className='app__team-wrapper'>
                     <div className='app_team-head__info'>
                    <h2 className='p__cormorant'>Георги Каримов - частен съдебен изпълнител с район на действие ОС София
                        <p className='p__opensans'>E-mail: gkarimov@gmail.com</p>
                    </h2>
                    <div className='app_team-head__info-content'>
                        <p className='p__opensans'>През 1990 г. завършва висшето си образование в гр. София.</p>
                        <p className='p__opensans'>От 01.10.1994 г. до 26.01.2000 г. е съдия изпълнител в Районен съд-Самоков.</p>
                        <p className='p__opensans'>От 01.08.2000 г. до 01.08.2001 г. е съдия по вписванията в Районен съд-Самоков.</p>
                        <p className='p__opensans'> От 01.08.2001 г. до 23.12.2004 г. е районен съдия в Районен съд-Самоков.</p>
                        <p className='p__opensans'>От 29.12.2004 г. до 11.2005 г. е държавен съдебен изпълнител в Районен съд-Самоков.</p>
                        <p className='p__opensans'>От 25.05.2006 г. е действащ частен съдебен изпълнител и е регистриран в Камарата на ЧСИ с рег. № 792.</p>
                        <p className='p__opensans'></p>
                    </div>

                </div>
                <div className='app__team-empl'>
                    <p className='p__opensans'><span >Мариана Жишкина</span> - секретар</p>
                    <p className='p__opensans'><span >Наталия Зетова</span> - деловодител</p>
                    <p className='p__opensans'>Email: kantora.karimov@abv.bg</p>
                    <p className='p__opensans'><span>Иво Манчев</span> - призовкар</p>
                </div>
                </div>
               
            </div>

        </div>
    )
}

export default Team
