import React from 'react';
import './Powers.css';
import { images } from '../../constants';
import { Banner, SubHeading } from '../../components';

const Powers = () => {
  return (
    <div className='app__powers'>
      <Banner image={images.services} bg="app__bg3" />
      <div className='app__powers-title'>
        <SubHeading title='Правомощия' />
        <h1 data-aos="zoom-in"className='headtext__cormorant'> Правомощия на съдебния изпълнител</h1>
      </div>
      <div className='app__powers-content flex__center section__padding fade-in'>
        <div data-aos="fade-left" className='app__powers-border'>
          <p className='p__opensans'>Частният съдебен изпълнител извършва следните основни действия:</p>
            <ul className='app__powers-content__list'>
              <li className='p__opensans'>Принудително събиране на парични вземания</li>
              <li className='p__opensans'> По възлагане на органите, компетентни да установят публични вземания, принудително събиране на публични държавни вземания</li>
              <li className='p__opensans'>Принудително въвежда във владение на недвижими имоти и предава движими вещи</li>
              <li className='p__opensans'>Осъществява принуда срещу осъдените да изпълнят определено действие или бездействие</li>
            </ul>      
        </div>      
      </div>
      
      <div className='app__powers-content flex__center section__padding fade-in'>
        <div data-aos="fade-right" className='app__powers-border'>
          <p className='p__opensans'> Чл. 431. от ГПК</p>
            <p className='p__opensans'>(1) Съдебният изпълнител, ако това е необходимо за изпълнението, може да нареди да се отворят сгради на длъжника и да претърсва неговите вещи, жилище и други помещения.</p>
        <p className='p__opensans'>(2) Държавните учреждения, общините, организациите и гражданите са длъжни да оказват съдействие на съдебния изпълнител. При поискване полицейските органи са длъжни да окажат съдействие на съдебния изпълнител при възпрепятстване на изпълнението на неговите функции.</p>
        <p className='p__opensans'>(3) Съдебният изпълнител има право на достъп до информация в съдебните и административните служби, в т.ч. органите на Националната агенция за приходите, поделенията на Националния осигурителен институт, на централния регистър на ценни книжа, воден от Централния депозитар, на централните депозитари на ценни книжа, на лицата, водещи регистър на държавни ценни книжа, на контролните органи по Закона за движението по пътищата и на други лица, които водят регистри за имущество или разполагат с данни за неговото имущество. Той може да прави справки и да получава сведения във връзка с изпълнението, както и да иска копия и извлечения от документи.</p>
        <p className='p__opensans'>(4) За информацията по ал. 3, необходима за съответното изпълнително производство, както и за вписване на обезпечителни мерки по него се дължи съответната държавна или местна такса, освен в случаите по чл. 83, ал. 1 и чл. 84, както и когато информацията е получена по реда на Закона за електронното управление. Таксата се заплаща от взискателя и е за сметка на длъжника.</p>
        <p className='p__opensans'>(5) В случаите, когато личното присъствие на длъжника е необходимо и той не се явява, въпреки че е получил призовка за това, съдебният изпълнител може да нареди на полицейските органи неговото довеждане.</p>
        <p className='p__opensans'>(6) При необходимост съдебният изпълнител може да поиска от органите на Министерството на вътрешните работи спиране от движение на моторно превозно средство, върху което е насочено изпълнение за срок до три месеца.
        </p>
        </div>      
      </div>
    </div>
  )
}

export default Powers;
