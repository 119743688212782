import React from 'react';
import { BankAccounts, Navbar } from '../../components';
import { Footer, Contacts, Services } from '../../container';

const ServicesPage = () => {
  return (
    <div>
      <Navbar/>
      <Services/>
      <BankAccounts/>
      <Contacts/>
      <Footer/>
    </div>
  )
}

export default ServicesPage;

