import React from "react";
import {FiDownload} from 'react-icons/fi';
import { data } from "../../constants";

import "./Blanks.css";

const Blanks = () => {
  return (
    <div className='app__blanks-container app__bg flex__center section__padding'>
      <div data-aos="flip-down" className='app__blanks-container__item'>
        <a href={data.application1} className="row"><h3>Молба за участие в публична продан</h3><FiDownload/></a>
      </div>
      <div data-aos="flip-down"className='app__blanks-container__item'>
        <a href={data.application2} className="row"><h3>Молба за участие в публична продан от взискател/съделител</h3><FiDownload/></a>
      </div>
      <div data-aos="flip-down"className='app__blanks-container__item'>
        <a href={data.offer}className="row"><h3>Наддавателно предложение</h3><FiDownload/></a>
      </div>
      <div data-aos="flip-down"className='app__blanks-container__item'>
        <a href={data.applicationReturn}className="row"><h3>Молба за връщане на задатък</h3><FiDownload/></a>
      </div>
      <div data-aos="flip-down"className='app__blanks-container__item'>
        <a href={data.declarationNP}className="row"><h3>Декларация за произход на средствата за ФЛ</h3><FiDownload/></a>
      </div>
      <div data-aos="flip-down"className='app__blanks-container__item'>
        <a href={data.declarationLP}className="row"><h3>Декларация за произход на средствата за ЮЛ</h3><FiDownload/></a>
      </div>
      <div data-aos="flip-down"className='app__blanks-container__item'>
        <a href={data.appendix7NP}className="row"><h3>Въпросник за ФЛ - Приложение №7  от ЕВПКПИПФТ</h3><FiDownload/></a>
      </div>     
      <div data-aos="flip-down"className='app__blanks-container__item'>
        <a href={data.appendix7LP}className="row"><h3>Въпросник за ЮЛ - Приложение №7  от ЕВПКПИПФТ</h3><FiDownload/></a>
      </div>
    </div>
  );
};

export default Blanks;
