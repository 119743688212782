import React, { useEffect } from "react";
import { Link } from "react-router-dom";


import { MdArrowForwardIos } from 'react-icons/md'
import { images } from "../../constants";
import "./Services.css";
import { SubHeading, Banner } from "../../components";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {

  // useEffect(() => {
  //   AOS.init({ duration: 2000 });
  //   AOS.refresh();
  // }, []);
  return (
    <div className='app__services'>
      <Banner image={images.services} bg="app__bg3"/>
      <div className='app__services-title' id='services'>
        <SubHeading title='Услуги' />
        <h1 data-aos="zoom-in" className='headtext__cormorant'> Професионални Услуги</h1>
      </div>

      <div className='app__services-content section__padding'>
        <Link to="/sphere">
          <section data-aos="flip-right" className='app__services-section'>
            <div className='app__services-section_item'>
              <div className='app__services-section_item-img'>
                <img src={images.office} alt="documents" />
              </div>
              <div className='app__services-section_item-content'>
                <h3>Сфери на дейност</h3>
                <MdArrowForwardIos className="app__sales-icon" />
              </div>
            </div>
          </section>
        </Link>
        <Link to="/powers">
          <section data-aos="flip-right" className='app__services-section'>
            <div className='app__services-section_item'>
              <div className='app__services-section_item-img'>
                <img src={images.powers} alt="documents" />
              </div>
              <div className='app__services-section_item-content'>
                <h3>Правомощия</h3>
                <MdArrowForwardIos className="app__sales-icon" />
              </div>
            </div>
          </section>
        </Link>
        <Link to="/range">
          <section data-aos="flip-right" className='app__services-section'>
            <div className='app__services-section_item'>
              <div className='app__services-section_item-img'>
                <img src={images.map} alt="documents" />
              </div>
              <div className='app__services-section_item-content'>
                <h3>Район на действие</h3>
                <MdArrowForwardIos className="app__sales-icon" />
              </div>
            </div>
          </section>
        </Link>
      </div>
    </div>
  );
};

export default Services;
