import {useState, useEffect} from 'react';
import moment from "moment";
import './Notes.css'

const startTime = moment("2024-08-31");
const endTime = moment("2024-09-09");


const Notes = () => {
  const [visible, setVisible] = useState(false);
   
const currentDate=moment();

  useEffect(()=>{
    if(moment().isSame(startTime)||(moment().isAfter(startTime) && moment().isBefore(endTime))) {
      setVisible(true)     
    }else{
      setVisible(false);
    }
  }, [currentDate])

  return (
    <>
      <section className= 'notes' style={visible===true? {display:'flex'}: {display:'none'}} >      
          <p>
           Кантората на ЧСИ Георги Каримов е в годишен отпуск до 09.09.2024 г.
          </p>           
      </section>
    </>
  );
};

export default Notes;

