import React from 'react';
import { Navbar } from '../../components';
import { Footer, Powers } from '../../container';


const PowersPage = () => {
  return (
    <div>
      <Navbar/>
      <Powers/>
      <Footer/>
    </div>
  )
}

export default PowersPage;
